import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Experiment = {
    name: string
    enabled: boolean
}

export type ExperimentState = {
    experiments: Experiment[]
}

const initialState: ExperimentState = {
    experiments: [],
}

const experimentSlice = createSlice({
    name: 'experiment',
    initialState,
    reducers: {
        setExperiments: (state, { payload }: PayloadAction<Experiment[]>) => {
            state.experiments = payload
        },
        setExperimentState: (state, { payload }: PayloadAction<Experiment>) => {
            const index = state.experiments.findIndex(({ name }) => name === payload.name)

            if (index !== -1) {
                state.experiments[index] = payload
            } else {
                state.experiments.push(payload)
            }
        },
    },
})

export const { setExperiments, setExperimentState } = experimentSlice.actions

export default experimentSlice.reducer
