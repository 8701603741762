import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Popover, { PopoverOrigin } from '@material-ui/core/Popover'

type TOnPopOverOpen = (event: MouseEvent) => void

interface IPopoverWithComponentProps {
    openPopoverRenderer: (onClick: TOnPopOverOpen) => React.ReactNode
    children: React.ReactNode | ((handleClose: () => void) => React.ReactNode)
    className?: string
    anchorOriginHorizontal?: PopoverOrigin['horizontal']
    anchorOriginVertical?: PopoverOrigin['vertical']
    onClose?: () => void
    closeWithEnter?: boolean
    closeOnBackdropClick?: boolean
    onOpen?: () => void
    gapToAnchor?: number
    paperPadding?: number
}

const StyledPopover = styled(Popover)<{ paperPadding: number }>`
    & .MuiPopover-paper {
        padding: ${({ paperPadding }) => `${paperPadding}px`};
    }
`

/**
 * Popover component that you can easily render for any other component,
 * for example if you have a button from which you want to open a popover.
 */
const PopoverWithComponent: FC<IPopoverWithComponentProps> = ({
    openPopoverRenderer,
    children,
    className,
    anchorOriginHorizontal = 'right',
    anchorOriginVertical = 'bottom',
    paperPadding = 14,
    onClose,
    onOpen,
    closeWithEnter = false,
    closeOnBackdropClick = true,
    gapToAnchor,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | Element>(null)
    const lastClickCoords = useRef({ x: 0, y: 0 })

    const handleMenuOpen = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget)
        onOpen && onOpen()
    }

    const handleClose = useCallback(
        (event: any, reason: string) => {
            if (!closeOnBackdropClick && reason === 'backdropClick') {
                return
            }

            setAnchorEl(null)
            onClose && onClose()
        },
        [closeOnBackdropClick, onClose]
    )

    const menuOpen = Boolean(anchorEl)

    const addKeyBoardHandler = (handler: (event: KeyboardEvent) => void) => {
        const eventName = 'keyup'

        const isSupported = window && window.addEventListener
        if (!isSupported) {
            return
        }

        // Add event listener
        window.addEventListener(eventName, handler)

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener(eventName, handler)
        }
    }

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (closeWithEnter && event.key === 'Enter') {
                handleClose(event, '')
            }
        }

        const cleanup = addKeyBoardHandler(handler)

        return cleanup
    }, [closeWithEnter, handleClose])

    const renderChildren = () => {
        if (typeof children === 'function') {
            return children(() => handleClose(null, ''))
        }

        return children
    }

    return (
        <>
            {openPopoverRenderer(handleMenuOpen)}
            <StyledPopover
                data-testid="popover-with-component"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: anchorOriginVertical,
                    horizontal: anchorOriginHorizontal,
                }}
                className={className}
                getContentAnchorEl={null}
                onClose={handleClose}
                open={menuOpen}
                paperPadding={paperPadding}
                transformOrigin={{
                    vertical: gapToAnchor ? gapToAnchor * -1 : 'top',
                    horizontal: 'left',
                }}
                TransitionProps={{
                    timeout: {
                        enter: 400,
                        exit: 100,
                    },
                    onExited: () => {
                        requestAnimationFrame(() => {
                            const elementUnderCursor = document.elementFromPoint(
                                lastClickCoords.current.x,
                                lastClickCoords.current.y
                            )

                            if (elementUnderCursor) {
                                ;(elementUnderCursor as HTMLElement).click()
                            }
                        })
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'transparent',
                    },
                    onClick: (event) => {
                        lastClickCoords.current = {
                            x: event.clientX,
                            y: event.clientY,
                        }
                        handleClose(event, 'backdropClick')
                    },
                }}
            >
                {renderChildren()}
            </StyledPopover>
        </>
    )
}

export default PopoverWithComponent
