import { useDispatch, useSelector } from 'react-redux'
import { ListExcelButton, selectDataSourceId } from '../configurable-list'
import { selectListStaticActions } from '../configurable-list/State/ConfigurableListSelectors'
import { openFormViewConfigurableModalThunk } from '../form-view'
import { IListViewConfigurableModalAction } from '../configurable-list/interfaces/TFunctionalityAction'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'
import { orderBy } from 'lodash-es'
import { useCallback } from 'react'
import { Button, Icon } from '../generic-components'
import { getTestId } from '../test'
import { getResourceName } from './utils'
import { translate } from '../localization'

type Props = {
    listId: string
    showExcelButton: boolean
    marginTop?: number
}

const forcedPrimaryButtons = ['AnnualLeaveBalances_Synchronize']

const Container = styled.div<{ marginTop: number }>`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
`

const TopRowActions = ({ listId, showExcelButton, marginTop = 0 }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const dataSourceId = useSelector((state: RootState) => selectDataSourceId(state, listId))
    const staticActions = useSelector((state: RootState) => selectListStaticActions(state, listId))

    const handleClick = useCallback(
        (modalId: string) => {
            dispatch(openFormViewConfigurableModalThunk(modalId, dataSourceId, []))
        },
        [dataSourceId, dispatch]
    )

    const actions = orderBy(staticActions, 'Order', 'desc').map((action) => {
        const { Display, Functionality, Order } = action
        const { FormId, DataSourceActionId } = Functionality as IListViewConfigurableModalAction

        const isCreateButton = Display.ButtonText === 'Luo uusi'

        const resourceName = getResourceName(listId)

        const buttonText =
            !isCreateButton || !resourceName
                ? Display.ButtonText
                : `${translate('messaging.new')} ${getResourceName(listId)}`

        const key = `${FormId}-${buttonText}`

        return (
            <Button
                key={key}
                onClick={() => handleClick(FormId)}
                testId={getTestId(['LIST_BULK_ACTION', 'BTN'], key)}
                variant={
                    Order === 1 || isCreateButton || forcedPrimaryButtons.includes(DataSourceActionId ?? '')
                        ? 'contained'
                        : 'outlined'
                }
                startIcon={isCreateButton ? <Icon>add</Icon> : null}
                large
            >
                {buttonText}
            </Button>
        )
    })

    return (
        <Container marginTop={marginTop}>
            {showExcelButton && <ListExcelButton listId={listId} />}
            {actions}
        </Container>
    )
}

export default TopRowActions
