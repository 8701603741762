import MockAdapter from 'axios-mock-adapter/types'

import {
    fetchDataSourceDataMock,
    fetchDataSourceMetadataMock,
    IDataSourceConfiguration,
    VIEW_ENGINE_URL,
} from '../../../data-source'
import IListViewModel from '../../interfaces/IListViewModel'
import TestData from '../../test/TestData'

export const fetchListViewDataMock = (axiosMock: MockAdapter, listId: string, data: IListViewModel): void => {
    axiosMock.onGet(`${VIEW_ENGINE_URL}/ListView?id=${listId}&isModal=false`).reply(200, data)
}

export const postListViewUserSettingDataMock = (axiosMock: MockAdapter, listId: string): void => {
    axiosMock.onGet(`${VIEW_ENGINE_URL}/ListView/${listId}/UserSetting`).reply(200)
}

export const mockListDataFetches = (
    axiosMock: MockAdapter,
    listId: string,
    listviewMetadata?: Partial<IListViewModel>,
    dataSourceMetadata?: Partial<IDataSourceConfiguration>
): void => {
    const completeListViewMetaData = {
        ...TestData.getListViewMetadata(),
        ...listviewMetadata,
    }
    const completeDataSourceMetadata: IDataSourceConfiguration = {
        ...TestData.getDataSourceMetadata(),
        ...dataSourceMetadata,
    }
    const actualListData = TestData.getListActualData()

    axiosMock.onGet(`${VIEW_ENGINE_URL}/ListView?id=${listId}&isModal=false`).reply(200, completeListViewMetaData)

    fetchDataSourceMetadataMock(axiosMock, completeListViewMetaData.DataSourceId, completeDataSourceMetadata)

    const successfulResponse = true
    fetchDataSourceDataMock(axiosMock, `${completeDataSourceMetadata.Url}`, successfulResponse, actualListData)
}
