import * as React from 'react'
import Switch from '@material-ui/core/Switch'
import ReadOnlyText from '../../ReadOnlyText'
import styled from '@emotion/styled'
import Icon from '../../Icon'
import { Translation } from '../../../localization'

export interface IStyledToggleProps {
    label: string
    checked: boolean
    icon?: { enabled: string; disabled: string }
    textColor?: string
    onChange?: () => void
    disabled?: boolean
    testId?: string
}

const Container = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: -8px;
`

const StyledSwitch = styled(Switch)`
    & .MuiSwitch-switchBase {
        color: ${({ theme }) => theme.colors.neutralsGrey30};

        &:hover {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.textInputHelperDefault}15;
        }
    }

    & .MuiSwitch-track {
        opacity: 100%;
        background-color: ${({ theme }) => theme.colors.neutralsGrey60};
    }
    & .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) {
        color: ${({ theme }) => theme.colors.primaryTeal120};
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 100%;
        background-color: ${({ theme }) => theme.colors.primaryTeal30};
    }
`

const StyledIcon = styled(Icon)`
    margin-left: 0.5rem;
`

const Toggle = ({ label, onChange, checked, icon, textColor, disabled, testId }: IStyledToggleProps): JSX.Element => {
    const iconBlock = icon && <StyledIcon>{checked ? icon.enabled : icon.disabled}</StyledIcon>

    return (
        <Container data-testid={testId} onClick={onChange} disabled={disabled}>
            <StyledSwitch checked={checked} tabIndex={-1} disabled={disabled} />
            <ReadOnlyText usage="bodyS" translate={Translation.has(label)} rawColor={textColor}>
                {label}
            </ReadOnlyText>
            {iconBlock}
        </Container>
    )
}

export default Toggle
