import { Checkbox, ChipWithPopover, Icon } from '../generic-components'
import { useAppTheme } from '../theme'
import { useCallback, useEffect, useState } from 'react'
import ITogglePickerConfiguration from '../value-picker/types/ITogglePickerConfiguration'
import { setValuePickerValueAction } from '../value-picker/State/ValuePickerActions'
import { selectValuePickersValues } from '../value-picker/State/ValuePickerSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'
import { fetchDataSourceDataThunk, selectIsDataSourceDataFetched } from '../data-source'
import { CALENDAR_DATASOURCE_ID } from '../workforce-scheduling/v2/Constants'
import { selectOpenModalIds } from '../modal'
import { isEmpty } from 'lodash-es'
import { translate } from '../localization'

type Props = {
    valuePickers: ITogglePickerConfiguration[]
    showAutoRefresh?: boolean
}

const REFRESH_INTERVAL = 60000

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const SettingsSelector = ({ valuePickers, showAutoRefresh }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const { componentExtensions } = useAppTheme()

    const dataFetched = useSelector((state: RootState) => selectIsDataSourceDataFetched(state, CALENDAR_DATASOURCE_ID))
    const openModals = useSelector((state: RootState) => selectOpenModalIds(state))

    const [autoRefreshChecked, setAutoRefreshChecked] = useState<boolean>(true)

    const refreshData = useCallback(() => {
        dispatch(fetchDataSourceDataThunk(CALENDAR_DATASOURCE_ID, { showLoadingSpinner: false }))
    }, [dispatch])

    useEffect(() => {
        if (!autoRefreshChecked || !isEmpty(openModals) || !dataFetched) {
            return
        }

        const refreshInterval = setInterval(refreshData, REFRESH_INTERVAL)
        return () => clearInterval(refreshInterval)
    }, [refreshData, autoRefreshChecked, openModals, dataFetched])

    const values = useSelector((state: RootState) =>
        selectValuePickersValues(
            state,
            valuePickers.map(({ ValuePickerId }) => ValuePickerId)
        )
    )

    const handleChange = useCallback(
        (value: boolean, valuePicker: ITogglePickerConfiguration) => {
            const sideEffects = !value ? valuePicker.TrueValueSideEffects : valuePicker.FalseValueSideEffects

            dispatch(setValuePickerValueAction(!value, valuePicker.ValuePickerId, undefined, sideEffects))
        },
        [dispatch]
    )

    return (
        <ChipWithPopover
            label={<Icon color={componentExtensions.icons.iconAction}>settings</Icon>}
            spaceReservedForSelectionCount={18}
            selectionCount={
                Object.values(values).filter((value) => value === true).length +
                (showAutoRefresh && autoRefreshChecked ? 1 : 0)
            }
            isSelectionIconRendered
        >
            <CheckboxContainer>
                {valuePickers.map((valuePicker) => {
                    const value = Boolean(values[valuePicker.ValuePickerId])

                    return (
                        <Checkbox
                            key={valuePicker.ValuePickerId}
                            id={valuePicker.ValuePickerId}
                            label={valuePicker.Label}
                            value={value}
                            onClick={() => handleChange(value, valuePicker)}
                        />
                    )
                })}
                {showAutoRefresh && (
                    <Checkbox
                        label={translate('Staffing.autoRefresh')}
                        id="auto-refresh-checkbox"
                        value={autoRefreshChecked}
                        onClick={() => setAutoRefreshChecked((checked) => !checked)}
                    />
                )}
            </CheckboxContainer>
        </ChipWithPopover>
    )
}

export default SettingsSelector
