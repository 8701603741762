import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { difference, groupBy, isEmpty, mapValues, partition, sortBy } from 'lodash-es'
import { EValuePickerType, selectValuePickerConfigurations, TValuePickerId, ValuePickerRenderer } from '../value-picker'
import { ListFetchButton, selectListViewFilterIds, setFiltersHeightAction, TABS_HEIGHT } from '../configurable-list'
import { RootState } from 'typesafe-actions'
import TopRowActions from './TopRowActions'
import ResetFiltersButton from './ResetFiltersButton'
import { Icon } from '../generic-components'
import { selectShowFetchDataButton } from '../configurable-list/State/ConfigurableListSelectors'
import IBaseValuePickerConfiguration from '../value-picker/types/IBaseValuePickerConfiguration'
import ListActionsBar from './ListActionsBar'
import { translate } from '../localization'
import { useAppTheme } from '../theme'
import SaveFiltersButton from './SaveFiltersButton'
import SavedFiltersSelector from './SavedFiltersSelector'
import { useIsFilterSavingEnabled } from './hooks'
import SettingsSelector from './SettingsSelector'
import ITogglePickerConfiguration from '../value-picker/types/ITogglePickerConfiguration'
import {
    ActionBarContainer,
    FiltersBadgeContainer,
    PickerGroup,
    TopRow,
    ValuePickersContainer,
} from './layoutComponents'
import { useResizeObserver } from '../generic-utilities'
import ToggleFiltersVisibilityButton from './ToggleFiltersVisibilityButton'
import { selectIsListInModal } from '../modal/State/ModalSelectors'
import ActiveFiltersBadge from './ActiveFiltersBadge'

type ActionBarProps = {
    listId: string
    omitFilterIds?: TValuePickerId[]
    showExcelButton?: boolean
    showAutoRefresh?: boolean
    accumulatedData?: ReactNode
    additionalButtons?: ReactNode
    onModalSubmitCallback?: () => void
}

const valuePickerGroups: Record<string, string[]> = {
    topRow: ['DateRange', 'DateRangeV2', 'TextSearch'],
    dropdowns: ['Dropdown'],
    toggles: ['Toggle'],
}

const valuePickerComponentMapping: Record<string, EValuePickerType> = {
    [EValuePickerType.Toggle]: EValuePickerType.ToggleButton,
    [EValuePickerType.TextSearch]: EValuePickerType.TextPicker,
    [EValuePickerType.TimeRange]: EValuePickerType.TimeRangeChip,
}

// these appear as checkboxes inside the gear icon -dropdown
const pickersMovedToSettings = ['IncludeDeletedEvents', 'PublishedTimesDifferFromActual', 'GroupSimilarEvents']

const ActionBarV2 = ({
    listId,
    accumulatedData,
    additionalButtons,
    onModalSubmitCallback,
    showExcelButton = false,
    showAutoRefresh,
    omitFilterIds = [],
}: ActionBarProps): JSX.Element => {
    const dispatch = useDispatch()

    const { componentExtensions } = useAppTheme()

    const filtersRef = useRef<HTMLDivElement>(null)
    const { height } = useResizeObserver(filtersRef)

    const topRowFiltersRef = useRef<HTMLDivElement>(null)
    const topRowHeight = topRowFiltersRef.current?.getBoundingClientRect().height ?? 40

    useEffect(() => {
        dispatch(setFiltersHeightAction(height + TABS_HEIGHT, listId))
    }, [dispatch, height, listId])

    const [filtersVisible, setFiltersVisible] = useState(true)

    const listViewFilterIds = useSelector((state: RootState) => selectListViewFilterIds(state, listId))
    const showFetchDataButton = useSelector((state: RootState) => selectShowFetchDataButton(state, listId))
    const isInModal = useSelector((state: RootState) => selectIsListInModal(state, listId))

    const filterSavingEnabled = useIsFilterSavingEnabled()

    const filterIds = difference(listViewFilterIds, omitFilterIds)

    const valuePickerConfigurations = useSelector((state: RootState) =>
        selectValuePickerConfigurations(state, filterIds)
    ) as IBaseValuePickerConfiguration<unknown>[]

    const [settingsPickers, remainingPickers] = partition(valuePickerConfigurations, ({ ValuePickerId }) =>
        pickersMovedToSettings.includes(ValuePickerId)
    )

    const configurationGroups = useMemo(() => {
        return mapValues(
            groupBy(remainingPickers, ({ ValuePickerType }) => {
                return Object.keys(valuePickerGroups).find((key) => valuePickerGroups[key].includes(ValuePickerType))
            }),
            (group, key) => {
                return sortBy(group, (picker) => valuePickerGroups[key]?.indexOf(picker.ValuePickerType))
            }
        )
    }, [remainingPickers])

    const hasFilters = !isEmpty([
        ...(configurationGroups.dropdowns || []),
        ...(configurationGroups.toggles || []),
        ...(configurationGroups.undefined || []),
    ])

    const fetchContentButton = showFetchDataButton && <ListFetchButton listId={listId} />

    const renderValuePickers = useCallback(
        (valuePickers?: IBaseValuePickerConfiguration<unknown>[]) => {
            if (!valuePickers) {
                return null
            }

            const getExtraProps = (label: string): Record<string, Record<string, unknown>> => ({
                [EValuePickerType.TextSearch]: {
                    placeholder: translate('hae'),
                    overrideStyle: { width: '240px' },
                    label: label === translate('hae') ? '' : label,
                    endAdorment: (
                        <Icon color={componentExtensions.icons.iconAction} size={24}>
                            search
                        </Icon>
                    ),
                },
                [EValuePickerType.DateRangeV2]: {
                    label: 'timeSpan',
                },
            })

            return valuePickers.map(({ ValuePickerId, ValuePickerType, ...rest }) => (
                <ValuePickerRenderer
                    key={ValuePickerId}
                    valuePickerId={ValuePickerId}
                    valuePickerType={valuePickerComponentMapping[ValuePickerType] || ValuePickerType}
                    fallbackComponent="ValuePicker"
                    useCachedValuesForOptions
                    {...getExtraProps(rest.Label ?? '')[ValuePickerType]}
                    {...rest}
                />
            ))
        },
        [componentExtensions]
    )

    return (
        <ActionBarContainer isInModal={isInModal} noFilters={!hasFilters} ref={filtersRef}>
            <ValuePickersContainer filtersVisible={filtersVisible && hasFilters}>
                <TopRow noFilters={!hasFilters}>
                    <PickerGroup ref={topRowFiltersRef}>
                        {renderValuePickers(configurationGroups.topRow)}

                        {filterSavingEnabled && <SavedFiltersSelector filterIds={filterIds} />}

                        {fetchContentButton}

                        {!filtersVisible && (
                            <FiltersBadgeContainer>
                                <ActiveFiltersBadge filterIds={filterIds} />
                            </FiltersBadgeContainer>
                        )}
                    </PickerGroup>

                    <TopRowActions
                        listId={listId}
                        showExcelButton={showExcelButton}
                        marginTop={topRowHeight >= 65 ? 25 : 0}
                    />
                </TopRow>

                {filtersVisible && (
                    <>
                        <PickerGroup>
                            {renderValuePickers([
                                ...(sortBy(
                                    [
                                        ...(configurationGroups.dropdowns || []),
                                        ...(configurationGroups.undefined || []),
                                    ],
                                    'Text.Label'
                                ) || []),
                            ])}

                            {(!isEmpty(settingsPickers) || showAutoRefresh) && (
                                <SettingsSelector
                                    valuePickers={settingsPickers as ITogglePickerConfiguration[]}
                                    showAutoRefresh={showAutoRefresh}
                                />
                            )}
                        </PickerGroup>
                        <PickerGroup>
                            {renderValuePickers(configurationGroups.toggles)}
                            <ResetFiltersButton filterIds={filterIds} />
                            {filterSavingEnabled && <SaveFiltersButton filterIds={filterIds} />}
                        </PickerGroup>
                    </>
                )}
            </ValuePickersContainer>

            {hasFilters && (
                <ToggleFiltersVisibilityButton
                    filtersVisible={filtersVisible}
                    onClick={() => setFiltersVisible((current) => !current)}
                />
            )}

            <ListActionsBar
                listId={listId}
                accumulatedData={accumulatedData}
                additionalButtons={additionalButtons}
                onModalSubmitCallback={onModalSubmitCallback}
            />
        </ActionBarContainer>
    )
}

export default ActionBarV2
