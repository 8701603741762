import { ICalendarAction, ICalendarConfiguration, IGroupingConfiguration } from '../Types/types'
import { EActionFunctionalityType } from '../../../view-engine'
import TFunctionalityAction from '../../../configurable-list/interfaces/TFunctionalityAction'
import { Translation } from '../../../localization'

const GroupingConfiguration: IGroupingConfiguration = {
    type: 'WorkUnit',
    displayType: 'MetricsOnly',
    subGroup: {
        type: 'JobTitle',
        displayType: 'EmptyEventsOnly',
        subGroup: {
            type: 'Employee',
            displayType: 'Events',
        },
    },
}

export const OpenEmployeeEditForm: TFunctionalityAction = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: '',
    FormId: 'EmployeeModifyForm',
    AdditionalPropertyMapping: [],
    AdditionalInitialValueSourceProperties: [],
    AdditionalInitialValues: [],
    IgnoreInitialValueFields: [],
    PermissionId: 'EDIT',
}

export const OpenWorkunitEditForm: TFunctionalityAction = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: '',
    FormId: 'WorkunitModifyForm',
    AdditionalPropertyMapping: [],
    AdditionalInitialValueSourceProperties: [],
    AdditionalInitialValues: [],
    IgnoreInitialValueFields: [],
    PermissionId: 'EDIT',
}

export const CopyAction: ICalendarAction = {
    Functionality: {
        FunctionalityType: EActionFunctionalityType.Immediate,
        DataSourceActionId: 'Event_Copy_Tapahtuma',
        // TODO: currently there's no permission of its own for this so just use UPDATE for now
        PermissionId: 'CREATE',
    },
    Display: {
        Icon: 'content_copy',
        Label: Translation.translateKey('Kopioi'),
    },
}

export const CutAction: ICalendarAction = {
    Functionality: {
        FunctionalityType: EActionFunctionalityType.Immediate,
        DataSourceActionId: 'Event_Cut_Tapahtuma',
        // TODO: currently there's no permission of its own for this so just use UPDATE for now
        PermissionId: 'CREATE',
    },
    Display: {
        Icon: 'content_copy',
        Label: Translation.translateKey('Kopioi'),
    },
}

export const CreateNewAction: ICalendarAction = {
    Functionality: {
        FunctionalityType: EActionFunctionalityType.ConfigurableModal,
        DataSourceActionId: 'Event_Create',
        FormId: 'Event_Create',
        AdditionalPropertyMapping: [],
        AdditionalInitialValueSourceProperties: [
            { key: 'Asiakas.Id', PropertyPath: 'workunitId' },
            { key: 'Tyontekija.Id', PropertyPath: 'employeeId', liftToArray: true },
            { key: 'Ammattinimike.Id', PropertyPath: 'jobTitleId' },
            { key: 'Alkamisaika', PropertyPath: 'StartTime', liftToArray: true, format: 'toDate' },
        ],
        AdditionalInitialValues: [],
        IgnoreInitialValueFields: [],
        IgnoreOriginalItemIds: true,
        PermissionId: 'CREATE',
    },
    Display: {
        Icon: 'add',
        Label: Translation.translateKey('Luo uusi'),
    },
    IsPrimaryAction: true,
}

export const NodeActions: ICalendarAction[] = [
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.ConfigurableModal,
            DataSourceActionId: 'Event_Edit',
            FormId: 'Event_Edit',
            AdditionalPropertyMapping: [],
            IgnoreInitialValueFields: [],
            PermissionId: 'UPDATE',
        },
        Display: {
            Icon: 'edit',
            Label: Translation.translateKey('Muokkaa'),
        },
        IsPrimaryAction: true,
        IsDoubleClickAction: true,
    },
    CopyAction,
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.ConfigurableModal,
            DataSourceActionId: 'Event_CreateTemplateFromEvent',
            PermissionId: 'CREATE',
            FormId: 'Event_CreateTemplateFromEvent',
            AdditionalPropertyMapping: [],
            IgnoreInitialValueFields: [],
        },
        Display: {
            Icon: 'backup_table',
            Label: Translation.translateKey('Luo tapahtumapohja'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.ListModal,
            DataSourceActionId: 'Event_Add_Tyontekija',
            PermissionId: 'SET_EMPLOYEE',
            HiddenValuePickerIds: [
                'WorkforceScheduling_PlanningPeriods',
                'WorkforceScheduling_Calendar_Filter_Employees',
            ],
            ValuePickerPropertyMapping: [],
            ListId: 'SoveltuvatTyontekijatList',
            ModalTitle: 'Soveltuvan työntekijän haku',
            SelectedItemsOutputProperty: 'tapahtumaIdt',
            OnRowClickItemOutProperty: 'TyontekijaId',
        },
        Display: {
            Icon: 'person_add',
            Label: Translation.translateKey('Lisää työntekijä'),
        },
        IsPrimaryAction: true,
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.CustomModal,
            DataSourceActionId: 'Event_PublishEvents',
            FormId: 'PublishEvents',
            PermissionId: 'PUBLISH',
        },
        Display: {
            Icon: 'publish',
            Label: Translation.translateKey('Julkaise'),
        },
        IsPrimaryAction: true,
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.ConfigurableModal,
            DataSourceActionId: 'Event_CreateEventTemplateGroup',
            FormId: 'Event_CreateEventTemplateGroup',
            AdditionalPropertyMapping: [],
            IgnoreInitialValueFields: [],
            // TODO: need to add a field to output the selected IDs
            PermissionId: 'CREATE',
            SelectedItemsOutputProperty: 'EventIds',
        },
        Display: {
            Icon: 'folder_open',
            Label: Translation.translateKey('Luo profiili'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.CustomModal,
            DataSourceActionId: 'Event_Remove_Tapahtuma',
            FormId: 'DeleteEvents',
            PermissionId: 'DELETE',
        },
        Display: {
            Icon: 'delete',
            Label: Translation.translateKey('Poista'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.CustomModal,
            DataSourceActionId: 'Event_Cancel',
            FormId: 'CancelEventForm',
            PermissionId: 'CANCEL_PUBLISHED_EVENT',
        },
        Display: {
            Icon: 'cancel',
            Label: Translation.translateKey('Peru vuoro'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.ConfigurableModal,
            DataSourceActionId: 'Event_EventRecurrence_SetEndTime',
            FormId: 'Event_EventRecurrence_SetEndTime',
            PermissionId: 'RECURRING_EVENT_SET_END_DATE',
        },
        Display: {
            Icon: 'delete',
            Label: Translation.translateKey('Päätä tapahtumasarja'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.Immediate,
            DataSourceActionId: 'Event_CombineEvents',
            PermissionId: 'UPDATE',
        },
        Display: {
            Icon: 'call_merge',
            Label: Translation.translateKey('Yhdistä tapahtumat yhdessä tehtäväksi'),
        },
        RequiresMultipleItems: true,
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.Immediate,
            DataSourceActionId: 'Event_DetachEvents',
            PermissionId: 'DETACH_FROM_EVENTGROUP',
        },
        Display: {
            Icon: 'call_split',
            Label: Translation.translateKey('Irrota tapahtuma tapahtumaryhmästä'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.Immediate,
            DataSourceActionId: 'Event_Swap_Tapahtuma',
            PermissionId: 'UPDATE',
        },
        Display: {
            Icon: 'swap_horizontal',
            Label: Translation.translateKey('Vaihda'),
        },
        RequiresMultipleItems: true,
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.Immediate,
            DataSourceActionId: 'Event_Remove_Tyontekija',
            PermissionId: 'REMOVE_EMPLOYEE',
        },
        Display: {
            Icon: 'person_remove',
            Label: Translation.translateKey('Irrota työntekijä'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.Immediate,
            DataSourceActionId: 'ConfirmTransfersToEvents',
            // TODO: currently there's no permission of its own for this so just use UPDATE for now
            PermissionId: 'CREATE_TRANSFER_EVENT_FROM_TEMPORARY_EVENT',
        },
        Display: {
            Icon: 'commute',
            Label: Translation.translateKey('Luo tapahtuma siirtymästä'),
        },
    },
    {
        Functionality: {
            FunctionalityType: EActionFunctionalityType.ConfigurableModal,
            DataSourceActionId: 'Event_CreateLeave',
            PermissionId: 'CREATE_LEAVE_FROM_EVENT',
            FormId: 'Event_CreateLeave',
            AdditionalPropertyMapping: [],
            IgnoreInitialValueFields: [],
        },
        Display: {
            Icon: 'airline_seat_individual_suite',
            Label: Translation.translateKey('Merkitse poissaoloksi'),
        },
    },
]

export const DatePickerId = 'DateRangeV2Picker'
export const EventTypePickerId = 'EventType_V3'
export const EMPLOYEE_PICKER_ID = 'Employee_V3'
export const WORKUNIT_PICKER_ID = 'Workunit_V3'
export const MainEventsOnlyPickerId = 'MainEventsOnly'

export const CalendarConfiguration: ICalendarConfiguration = {
    valuePickerIds: [
        DatePickerId,
        'StaffingTextSearch',
        'Area_V3',
        'Workunit_V3',
        'Employee_V3',
        'Pool_V3',
        'JobTitle_V3',
        'EventType_V3',
    ],
    groupingConfiguration: GroupingConfiguration,
}

export const StaffingValuePickerIds = [
    DatePickerId,
    'StaffingTextSearch',
    'WorkUnitName',
    'Area_V3',
    'Workunit_V3',
    'Pool_V3',
    'Employee_V3',
    'JobTitle_V3',
    'IndustryWorkUnitIndustry_V3',
    'EventTypeFilter_Staffing',
]

export const StaffingToggleFiltersValuePickerIds = [
    'ShowOnlyPublishedEventsFilter',
    'ShowOnlyEventsWithEmployeeFilter',
    'ShowAlsoEventsWithConfirmationFilter',
    //'GroupSimilarEvents',
]

export const getBackroundColorForColorId = (colorId: string): string => {
    if (colorId === 'base_event') {
        return '#fff'
    } else if (colorId === 'base_uneven') {
        return '#F2FAFB'
    }

    return '#fff'
}
