import { Toggle } from '../../generic-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsExperimentEnabled } from '../../redux/experiment/experimentSelectors'
import { RootState } from 'typesafe-actions'
import { Experiment, setExperiments, setExperimentState } from '../../redux/experiment/experimentReducer'
import { useEffect } from 'react'
import { getJSONItemFromLocalStorage, setJSONItemToLocalStorage } from '../StorageUtils'
import { isEmpty, isNil } from 'lodash-es'
import { useAppTheme } from '../../theme'
import styled from '@emotion/styled'

type Props = {
    flag: string
}

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.primaryTeal140};
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
`

const ExperimentToggle = ({ flag }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const { colors } = useAppTheme()

    const isEnabled = useSelector((state: RootState) => selectIsExperimentEnabled(state, flag))
    const experimentState = useSelector((state: RootState) => state.experiment.experiments)

    useEffect(() => {
        if (isEmpty(experimentState)) {
            const savedExperimentState = getJSONItemFromLocalStorage<Experiment[]>('experiments')

            if (!isNil(savedExperimentState)) {
                dispatch(setExperiments(savedExperimentState))
            }
        }

        setJSONItemToLocalStorage('experiments', experimentState)
    }, [dispatch, experimentState])

    return (
        <Container>
            <Toggle
                label={flag}
                checked={isEnabled}
                textColor={colors.neutralsWhite100}
                onChange={() => dispatch(setExperimentState({ name: flag, enabled: !isEnabled }))}
            />
        </Container>
    )
}

export default ExperimentToggle
