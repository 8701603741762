import { PageContainer } from '../../../../styling'
import { ConfigurableListPageTemplate } from '../../../../configurable-list'
import { TOTEUMAT_LIST_ID } from '../../VuorotJaVapaatPage/Constants/VuorotJaVapaatConstants'
import ToteumaSiirtoStatusTabs from './ToteumaSiirtoStatusTabs'
import { selectIsExternalUser } from '../../../../legacy/reducers/UserSelectors'
import { useSelector } from 'react-redux'
import { FEATURE_FLAGS, useIsExperimentEnabled } from '../../../../generic-utilities/FeatureFlag/utils'

const ToteumatPage = (): JSX.Element => {
    const isExternalUser = useSelector(selectIsExternalUser)
    const isExperimentEnabled = useIsExperimentEnabled(FEATURE_FLAGS.ACTION_BAR_RENEWAL)

    return (
        <>
            {!isExperimentEnabled && (
                <ToteumaSiirtoStatusTabs listId={TOTEUMAT_LIST_ID} isExternalUser={isExternalUser} />
            )}

            <PageContainer>
                <ConfigurableListPageTemplate listId={TOTEUMAT_LIST_ID} />
            </PageContainer>
        </>
    )
}

export default ToteumatPage
