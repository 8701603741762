import * as React from 'react'
import { memo } from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'

interface IGroupNodeContainerProps {
    backgroundShading: boolean
    groupColor: string | null
}

const NodeContainer = styled.div<IGroupNodeContainerProps>`
    display: flex;
    box-sizing: border-box;
    height: 30px;
    min-height: 30px;
    width: 200px;
    border-bottom: thin solid ${Styles.calendar.innerBorder};

    background-color: ${({ backgroundShading }: IGroupNodeContainerProps) =>
        backgroundShading ? Styles.calendar.segmentBackgroundShading : Styles.calendar.segmentBackgroundWhite};

    ${({ groupColor }) =>
        groupColor &&
        `
        border-left: 2px solid ${groupColor};
    `}
`

const GroupNodeContainer: React.FunctionComponent<IGroupNodeContainerProps> = ({
    groupColor,
    backgroundShading,
    children,
}) => (
    <NodeContainer backgroundShading={backgroundShading} groupColor={groupColor}>
        {children}
    </NodeContainer>
)

export default memo(GroupNodeContainer)
