import { useSelector } from 'react-redux'
import { selectIsExperimentEnabled } from '../../redux/experiment/experimentSelectors'
import { RootState } from 'typesafe-actions'

export const EXPERIMENT_FLAGS = {
    ACTION_BAR_RENEWAL: 'ACTION_BAR_RENEWAL',
} as const

export const FEATURE_FLAGS = {
    ACTION_BAR_RENEWAL: 'ACTION_BAR_RENEWAL',
} as const

// these can't be evaluated dynamically (as in process.env[flag])
const flagValues = {
    [FEATURE_FLAGS.ACTION_BAR_RENEWAL]: process.env.ACTION_BAR_RENEWAL,
}

export const isFeatureEnabled = (flag: string): boolean => {
    return flagValues[flag as keyof typeof FEATURE_FLAGS] === 'true'
}

export const useIsExperimentEnabled = (flag: string): boolean => {
    const isEnabled = useSelector((state: RootState) => selectIsExperimentEnabled(state, flag))

    return isEnabled || false
}
