import { Action } from 'redux'
import { createReducer } from 'typesafe-actions'

import IValuePicker from '../types/IValuePicker'
import { IDynamicDataValue } from '../types/IDynamicDataPickerProps'
import IValuePickerReducerState from '../types/IValuePickerReducerState'
import EValuePickerType from '../types/EValuePickerType'
import {
    initializeValuePickersAction,
    loadDefaultValuePickersValues,
    loadSavedValuePickersValues,
    removeValuePickerAction,
    resetValuePickerValuesAction,
    setValuePickersValuesAction,
    setValuePickerValueAction,
} from './ValuePickerActions'
import TValuePickerId from '../types/TValuePickerId'
import { getValuePickerValueInCorrectFormat, SAVED_PICKERS_PREFIX } from '../Utilities/ValuePickerValueUtilities'
import { getLogger } from '../../log'
import { getJSONItemFromLocalStorage } from '../../generic-utilities/StorageUtils'

const Log = getLogger('value-picker.ValuePickerReducer')

export const INITIAL_STATE: IValuePickerReducerState = new Map()

const setValuePickerValueToState = (
    state: Map<string, any>,
    valuePickerId: string,
    value: unknown,
    contextId?: string,
    sideEffects?: { key: string; value: unknown }[],
    note?: string
) => {
    const valuePickerState = state.get(valuePickerId)

    if (!valuePickerState) {
        return state
    }

    const { ValuePickerType } = valuePickerState.configuration

    let valueEnsuredToBeInCorrectFormat
    // DynamicData needs to hold values for all of its fields.
    // Here we only change values for the corresponding propertyId
    if (value && value instanceof Array && ValuePickerType === EValuePickerType.DynamicData) {
        const oldValue = valuePickerState.value
        if (oldValue instanceof Array) {
            const index = oldValue.findIndex(
                (existingValue: IDynamicDataValue) => existingValue.PropertyId === value[0].PropertyId
            )
            if (index >= 0) {
                oldValue.splice(index, 1, value[0])
                valueEnsuredToBeInCorrectFormat = oldValue
            } else {
                oldValue.push(value[0])
                valueEnsuredToBeInCorrectFormat = oldValue
            }
        } else {
            valueEnsuredToBeInCorrectFormat = value
        }
    } else {
        //If we handle form values we can treat the value as it is. List valuepickers modify the format of the data. TODO Fix list valuepickers
        valueEnsuredToBeInCorrectFormat = getValuePickerValueInCorrectFormat(ValuePickerType, value, Boolean(contextId))
    }

    if (contextId) {
        const contextualValue = valuePickerState.contextualValues
            ? new Map(valuePickerState.contextualValues)
            : new Map()

        const contextualValueNotes = valuePickerState.contextualValueNotes
            ? new Map(valuePickerState.contextualValueNotes)
            : new Map()

        const contextualValueSideEffects = valuePickerState.contextualValueSideEffects
            ? new Map(valuePickerState.contextualValueSideEffects)
            : new Map()

        contextualValueNotes.set(contextId, note)

        contextualValueSideEffects.set(contextId, sideEffects)

        contextualValue.set(contextId, valueEnsuredToBeInCorrectFormat)

        const newValuePickerState = {
            ...valuePickerState,
            value: valueEnsuredToBeInCorrectFormat,
            contextualValues: contextualValue,
            isInitialized: true,
            contextualValueSideEffects,
            contextualValueNotes,
        }

        state.set(valuePickerId, newValuePickerState)
    } else {
        const newValuePickerState = {
            ...valuePickerState,
            value: valueEnsuredToBeInCorrectFormat,
            isInitialized: true,
            sideEffects,
            note: note,
        }

        state.set(valuePickerId, newValuePickerState)
    }

    return state
}

const getStoredValuePickerValue = (page: string, valuePicker: IValuePicker) => {
    const savedValues = getJSONItemFromLocalStorage<Record<string, string>>(`${SAVED_PICKERS_PREFIX}-${page}`)

    if (savedValues) {
        let valuesToUse = savedValues

        // backwards compatibility with new filter saving system
        if (Object.keys(savedValues).includes('Oletusvalinta') && typeof savedValues.Oletusvalinta === 'object') {
            valuesToUse = { ...savedValues, ...(savedValues.Oletusvalinta as Record<any, any>) }
        }

        const value = getValuePickerValueInCorrectFormat(
            valuePicker.configuration.ValuePickerType,
            valuesToUse[valuePicker.configuration.ValuePickerId]
        )

        if (!value) {
            return
        }

        return {
            ...valuePicker,
            value,
        }
    }

    // fall back to old format
    const oldFormatValue = getJSONItemFromLocalStorage(valuePicker.configuration.ValuePickerId)

    if (oldFormatValue) {
        return {
            ...valuePicker,
            value: getValuePickerValueInCorrectFormat(valuePicker.configuration.ValuePickerType, oldFormatValue),
        }
    }
}

const logInvalidValuePickerWarning = (action: Action, valuePickerId: TValuePickerId) =>
    Log.warn(`Action ${action.type} dispatched to an invalid value picker ${valuePickerId}`)

const valuePickerReducer = createReducer<IValuePickerReducerState>(INITIAL_STATE)
    .handleAction(initializeValuePickersAction, (state, action) => {
        const { page, valuePickers } = action.payload

        const valuePickerEntries = valuePickers.map((valuePicker) => {
            const storedPicker = getStoredValuePickerValue(page, valuePicker)

            return [valuePicker.configuration.ValuePickerId, storedPicker ?? valuePicker] as [
                TValuePickerId,
                IValuePicker
            ]
        })

        const newState = new Map([...state, ...valuePickerEntries])

        return newState
    })
    .handleAction(resetValuePickerValuesAction, (state, action) => {
        const { value: valuePickersWithValues } = action.payload

        const newState = new Map(state)

        valuePickersWithValues.forEach(({ value }, valuePickerId) => {
            const valuePicker = state.get(valuePickerId)
            if (!valuePicker) {
                return
            }

            if (value !== undefined) {
                newState.set(valuePickerId, {
                    ...valuePicker,
                    value,
                    contextualValues: new Map(),
                    contextualValueNotes: new Map(),
                    contextualValueSideEffects: new Map(),
                })
            } else {
                newState.delete(valuePickerId)
            }
        })

        return newState
    })
    .handleAction(setValuePickerValueAction, (state, action) => {
        const { id, value, contextId, sideEffects, notes } = action.payload

        const newState = new Map(state)

        setValuePickerValueToState(newState, id, value, contextId, sideEffects, notes)

        return newState
    })
    .handleAction(setValuePickersValuesAction, (state, action) => {
        const { value: valuePickerIdWithValues, contextId } = action.payload

        const newState = new Map(state)

        valuePickerIdWithValues.forEach((value, valuePickerId) => {
            setValuePickerValueToState(newState, valuePickerId, value, contextId)
        })

        return newState
    })

    .handleAction(removeValuePickerAction, (state, action) => {
        const valuePickerId = action.payload

        const valuePickerState = state.get(valuePickerId)

        if (!valuePickerState) {
            logInvalidValuePickerWarning(action, valuePickerId)

            return state
        }

        const newState = new Map(state)

        newState.delete(valuePickerId)

        return newState
    })
    .handleAction(loadDefaultValuePickersValues, (state, action) => {
        const { page, valuePickerIds } = action.payload

        const newState = new Map(state)

        valuePickerIds.forEach((valuePickerId) => {
            const valuePicker = state.get(valuePickerId)
            if (!valuePicker) {
                return state
            }

            const storedValue = getStoredValuePickerValue(page, valuePicker)
            if (!storedValue) {
                return state
            }

            setValuePickerValueToState(newState, valuePickerId, storedValue.value, valuePickerId)
        })

        return newState
    })
    .handleAction(loadSavedValuePickersValues, (state, action) => {
        const { page, settingsName } = action.payload

        const newState = new Map(state)

        const settingsKey = `${SAVED_PICKERS_PREFIX}-${page}`

        const savedValues =
            getJSONItemFromLocalStorage<Record<string, Record<string, unknown>>>(settingsKey)?.[settingsName]

        if (savedValues) {
            Object.keys(savedValues).forEach((valuePickerId) => {
                const valuePicker = state.get(valuePickerId)
                if (!valuePicker) {
                    return state
                }

                const value = getValuePickerValueInCorrectFormat(
                    valuePicker.configuration.ValuePickerType,
                    savedValues[valuePicker.configuration.ValuePickerId]
                )

                if (!value) {
                    return
                }

                setValuePickerValueToState(newState, valuePickerId, value, valuePickerId)
            })
        }

        return newState
    })

export default valuePickerReducer
