import { VUOROT_JA_VAPAAT_LIST_ID } from '../payroll-management'
import { TOTEUMAT_LIST_ID } from '../payroll-management/Modules/VuorotJaVapaatPage/Constants/VuorotJaVapaatConstants'
import { translate } from '../localization'

export const getResourceName = (listId: string): string => {
    switch (listId) {
        case 'Staffing':
        case 'VuorotJaVapaatList':
            return translate('resources.event')

        case 'Employees':
            return translate('resources.employee')

        case 'ToteumaList':
            return translate('resources.transaction')
        default:
            return ''
    }
}

export const isStatusTabsVisible = (listId: string): boolean => {
    return listId === VUOROT_JA_VAPAAT_LIST_ID || listId === TOTEUMAT_LIST_ID
}
