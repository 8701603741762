import styled from '@emotion/styled'
import React, { PropsWithChildren, ReactNode } from 'react'
import Chip from './Chip'
import Icon from './Icon'
import ReadOnlyText from './ReadOnlyText'
import { translate } from '../localization'
import PopoverWithComponent from './PopoverWithComponent'

type Props = {
    label: ReactNode
    onDelete?: () => void
    spaceReservedForSelectionCount?: number
    isSelectionIconRendered?: boolean
    selectionCount?: number
}

const DeleteButton = styled.button`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primaryTeal120};

    span:last-of-type {
        margin-left: 4px;
    }
`

const ChipWithPopover = ({
    label,
    spaceReservedForSelectionCount,
    isSelectionIconRendered,
    selectionCount,
    onDelete,
    children,
}: PropsWithChildren<Props>): JSX.Element => {
    return (
        <PopoverWithComponent
            anchorOriginHorizontal="left"
            openPopoverRenderer={(onMenuOpen) => (
                <Chip
                    label={label}
                    spaceReservedForSelectionCount={spaceReservedForSelectionCount}
                    variant="filled"
                    isSelectionIconRendered={isSelectionIconRendered}
                    selectionCount={selectionCount}
                    onClick={onMenuOpen}
                    testId="chip-with-popover"
                />
            )}
        >
            {onDelete && (
                <DeleteButton onClick={onDelete}>
                    <Icon>close</Icon>
                    <ReadOnlyText usage="smallButton" color="primary_120">
                        {translate('Poista')}
                    </ReadOnlyText>
                </DeleteButton>
            )}

            {children}
        </PopoverWithComponent>
    )
}

export default ChipWithPopover
