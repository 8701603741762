import { useAreFiltersAtDefaultState } from './hooks'
import SelectionCountBadge from '../generic-components/SelectionCountBadge'

type Props = {
    filterIds: string[]
}

const ActiveFiltersBadge = ({ filterIds }: Props): JSX.Element | null => {
    const { changedFiltersCount } = useAreFiltersAtDefaultState(filterIds)

    if (changedFiltersCount === 0) {
        return null
    }

    return <SelectionCountBadge count={changedFiltersCount} />
}

export default ActiveFiltersBadge
