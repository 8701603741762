// For now TypeScript doesn't natively have the types for ResizeObserver
// https://github.com/Microsoft/TypeScript/issues/28502
/// <reference types="resize-observer-browser" />

import { memo, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

import ICalendarQuickActions from '../../Types/ICalendarQuickActions'
import IPublicHoliday from '../../Types/IPublicHoliday'

import ExpandableCalendar from './ExpandableCalendar'

type TExpandableCalendarProps = React.ComponentProps<typeof ExpandableCalendar>

const CalendarsContainer = styled.div<{
    thisRef: React.RefObject<HTMLDivElement>
    isCalendarExpandedRef: React.RefObject<boolean>
}>`
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    /* Ensure the max height goes correctly, starting from the top of the element */
    ${({ thisRef, isCalendarExpandedRef }) =>
        thisRef.current &&
        !isCalendarExpandedRef.current &&
        `
        max-height: ${window.innerHeight} - ${thisRef.current.getBoundingClientRect().top}px;
    `}
`

type TCalendarQuickActionsFunctionProp = (calendarId: string) => ICalendarQuickActions | null

interface IExpandableCalendarsProps
    extends Pick<TExpandableCalendarProps, 'isDataFetchedInInitialization' | 'areCalendarFiltersToBeDisplayed'> {
    calendarIds: string[]
    publicHolidays: IPublicHoliday[]
    hiddenValuePickerIds?: string[]
    quickActions?: (ICalendarQuickActions | null) | TCalendarQuickActionsFunctionProp
    getAreGroupsGrouped?: (calendarId: string) => boolean
    highlightExpandedDates: boolean
}

interface IStyledExpandableCalendarOwnProps {
    allCalendarsCount: number
    expandedCalendarsCount: number
    publicHolidays: IPublicHoliday[]
    isCalendarExpandedRef: React.MutableRefObject<boolean>
    maxHeight: null | string
}

const StyledExpandableCalendar = styled<React.FC<TExpandableCalendarProps & IStyledExpandableCalendarOwnProps>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ maxHeight: height, allCalendarsCount, expandedCalendarsCount, isCalendarExpandedRef, ...rest }) => {
        if (allCalendarsCount === expandedCalendarsCount) {
            isCalendarExpandedRef.current = true
        }
        return <ExpandableCalendar {...rest} />
    }
)`
    ${({ maxHeight }) =>
        maxHeight &&
        `
        max-height: ${maxHeight};
    `}
`

const ExpandableCalendars: React.FC<IExpandableCalendarsProps> = ({
    calendarIds,
    hiddenValuePickerIds,
    quickActions,
    isDataFetchedInInitialization,
    areCalendarFiltersToBeDisplayed,
    getAreGroupsGrouped,
    publicHolidays,
    highlightExpandedDates,
}) => {
    const [expandedCalendars, setExpandedCalendars] = useState(new Set(calendarIds))
    const calendarsContainerRef = useRef<HTMLDivElement>(null)
    const isCalendarExpandedRef = useRef(false)

    const [maxHeightForLastCalendar, setMaxHeightForLastCalendar] = useState<string | null>(null)
    const observerRef = useRef<ResizeObserver | null>(null)

    const handleExpandToggle = (calendarId: string) => {
        const copiedSet = new Set(expandedCalendars)

        if (expandedCalendars.has(calendarId)) {
            copiedSet.delete(calendarId)
        } else {
            copiedSet.add(calendarId)
        }

        setExpandedCalendars(copiedSet)
        isCalendarExpandedRef.current = !isCalendarExpandedRef.current
    }

    useEffect(() => {
        // IE11 doesn't support resize observer so check for it here.
        if (!calendarsContainerRef.current || !window.ResizeObserver) {
            return
        }

        const cleanupObserver = () => {
            if (observerRef.current) {
                observerRef.current.disconnect()
                observerRef.current = null
            }
        }

        cleanupObserver()

        const calendarElements = calendarsContainerRef.current.children
        const lastCalendarElement = calendarElements[calendarElements.length - 1]

        const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame((): void | undefined => {
                if (!Array.isArray(entries) || !entries.length) {
                    return
                }

                const isLastCalendarExpanded = expandedCalendars.has(calendarIds[calendarIds.length - 1])

                if (!isLastCalendarExpanded) {
                    return
                }

                const newPositionForSecondCalendar = Math.floor(lastCalendarElement.getBoundingClientRect().top)
                const newHeightForlastCalendar = `${Math.floor(window.innerHeight - newPositionForSecondCalendar)}px`

                const currentHeight = parseInt(maxHeightForLastCalendar || '0', 10) || 0
                const newHeight = parseInt(newHeightForlastCalendar, 10) || 0

                // Only update if the difference is significant (more than 2px)
                if (Math.abs(currentHeight - newHeight) > 2) {
                    setMaxHeightForLastCalendar(newHeightForlastCalendar)
                }
            })
        }

        const observer = new ResizeObserver(observerCallback)

        observerRef.current = observer
        observer.observe(calendarsContainerRef.current.children[0])

        return () => cleanupObserver()
    }, [calendarIds, expandedCalendars, maxHeightForLastCalendar])

    const getMaxHeight = (index: number) => {
        return index === calendarIds.length - 1 ? maxHeightForLastCalendar : `${100 / calendarIds.length}%`
    }

    return (
        <CalendarsContainer
            isCalendarExpandedRef={isCalendarExpandedRef}
            ref={calendarsContainerRef}
            thisRef={calendarsContainerRef}
        >
            {calendarIds.map((calendarId, index) => (
                <StyledExpandableCalendar
                    publicHolidays={publicHolidays}
                    allCalendarsCount={calendarIds.length}
                    areCalendarFiltersToBeDisplayed={areCalendarFiltersToBeDisplayed}
                    areGroupsGrouped={getAreGroupsGrouped ? getAreGroupsGrouped(calendarId) : false}
                    calendarId={calendarId}
                    expandedCalendarsCount={expandedCalendars.size}
                    hiddenValuePickerIds={hiddenValuePickerIds}
                    highlightExpandedDates={highlightExpandedDates}
                    isCalendarExpandedRef={isCalendarExpandedRef}
                    isDataFetchedInInitialization={isDataFetchedInInitialization}
                    key={calendarId}
                    maxHeight={getMaxHeight(index)}
                    onExpandToggle={handleExpandToggle}
                    quickActions={typeof quickActions === 'function' ? quickActions(calendarId) : quickActions}
                />
            ))}
        </CalendarsContainer>
    )
}

export default memo(ExpandableCalendars)
