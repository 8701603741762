import styled from '@emotion/styled'
import { Icon } from '../generic-components'

type Props = {
    filtersVisible: boolean
    onClick: () => void
    className?: string
}

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    cursor: pointer;
    border-top: 1px solid ${({ theme }) => theme.colors.primaryTeal11};
    border-bottom-left-radius: ${({ theme }) => theme.tokens.radiusSmall};
    border-bottom-right-radius: ${({ theme }) => theme.tokens.radiusSmall};
    color: ${({ theme }) => theme.componentExtensions.buttons.textButtonTertiaryDefault};
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
    background-color: ${({ theme }) => theme.componentExtensions.background.primary};

    &:hover {
        background-color: ${({ theme }) => theme.colors.primaryTeal11};
    }
`

const ToggleFiltersVisibilityButton = ({ filtersVisible, className, onClick }: Props): JSX.Element => {
    return (
        <Button className={className} onClick={onClick}>
            <Icon>{filtersVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </Button>
    )
}

export default ToggleFiltersVisibilityButton
