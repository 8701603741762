import { translate } from '../localization'
import { useAreFiltersAtDefaultState, useResetValuePickerValues } from './hooks'
import { Button } from '../generic-components'
import styled from '@emotion/styled'

type Props = {
    filterIds: string[]
}

const StyledButton = styled(Button)`
    .MuiButton-label {
        font-size: 14px;
    }
`

const ResetFiltersButton = ({ filterIds }: Props): JSX.Element | null => {
    const { filtersAtDefaultState } = useAreFiltersAtDefaultState(filterIds)
    const resetValuePickerValues = useResetValuePickerValues(filterIds)

    if (filtersAtDefaultState) {
        return null
    }

    return (
        <StyledButton startIcon="clear" variant="text" onClick={resetValuePickerValues}>
            {translate('tyhjenna-valinnat')}
        </StyledButton>
    )
}

export default ResetFiltersButton
