import styled from '@emotion/styled'
import StyledButton, { IStyledButtonProps } from './Button/StyledButton'
import Icon from '../Icon'
import { pick } from 'lodash-es'
import { PopoverWithComponent } from '../index'
import { List, ListItem } from '../../action-bar/layoutComponents'
import ReadOnlyText from '../ReadOnlyText'

type Props = IStyledButtonProps & {
    menuItems: { text: string; divider?: boolean; onClick?: () => void }[]
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Button = styled(StyledButton)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`

const MenuToggle = styled(StyledButton)<Pick<Props, 'variant'>>`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 40px;
    width: 40px;
    border-left: ${({ variant, theme }) => {
        if (variant === 'outlined') {
            return 'none'
        }

        return `1px solid ${theme.colors.neutralsWhite100}`
    }};
`

const SplitButton = ({ children, menuItems, ...props }: Props): JSX.Element => {
    return (
        <Container>
            <Button {...props}>{children}</Button>

            <PopoverWithComponent
                paperPadding={0}
                anchorOriginHorizontal={-200}
                openPopoverRenderer={(onMenuOpen) => (
                    <MenuToggle onClick={onMenuOpen} {...pick(props, 'variant', 'large', 'small')}>
                        <Icon>keyboard_arrow_down</Icon>
                    </MenuToggle>
                )}
            >
                {(onClose) => (
                    <List>
                        {menuItems.map(({ text, divider, onClick }) => {
                            const handleClick = () => {
                                onClose()
                                onClick?.()
                            }

                            return (
                                <ListItem key={text} divider={divider} onClick={handleClick}>
                                    <ReadOnlyText usage="bodyS">{text}</ReadOnlyText>
                                </ListItem>
                            )
                        })}
                    </List>
                )}
            </PopoverWithComponent>
        </Container>
    )
}

export default SplitButton
