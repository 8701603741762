import { combineReducers } from 'redux'

import { configurableCalendarReducer } from '../calendar'
import { configurableListReducer } from '../configurable-list'
import { dataSourceReducer } from '../data-source'
import { modalReducer } from '../modal'
import { valuePickerInitialValuesReducer, valuePickerReducer } from '../value-picker'
import { calendarReducer, workforceSchedulingReducer } from '../workforce-scheduling'
import { formViewReducer } from '../form-view'
import { notificationReducer } from '../notifications'
import { osiokohtaisetAsetuksetReducer } from '../osiokohtaiset-asetukset'
import { tyovuorolistapakettiReducer } from '../tyovuorolistapaketti'
import { asyncOperationReducer } from '../async-operation'
import asetusReducer from 'reducers/AsetusReducer'
import { navigationReducer } from '../navigation'
import ohjeinfoReducer from 'reducers/OhjetiedostoReducer'
import tyyliReducer from 'reducers/TyyliReducer'
import userReducer from 'reducers/UserReducer'
import { pageReducer } from '../page'
import { storedOptionsReducer } from '../stored-options'
import { palkkausReducer } from '../yllapitokayttoliittymat'
import { messagingReducer } from '../messaging'
import { EmployeeCalendarReducer } from '../employee-calendar'
import experimentReducer from './experiment/experimentReducer'

export default combineReducers({
    asetukset: asetusReducer,
    calendarv2: calendarReducer,
    calendar: configurableCalendarReducer,
    dataSource: dataSourceReducer,
    formView: formViewReducer,
    listData: configurableListReducer,
    loginUser: userReducer,
    modal: modalReducer,
    navigationItems: navigationReducer,
    notifications: notificationReducer,
    ohjeinfot: ohjeinfoReducer,
    osiokohtaisetAsetukset: osiokohtaisetAsetuksetReducer,
    pageData: pageReducer,
    tyovuorolistapaketit: tyovuorolistapakettiReducer,
    tyylit: tyyliReducer,
    asyncOperationState: asyncOperationReducer,
    storedOptions: storedOptionsReducer,
    valuePicker: valuePickerReducer,
    valuePickerInitialValues: valuePickerInitialValuesReducer,
    PalkkausPage: palkkausReducer,
    workforceScheduling: workforceSchedulingReducer,
    messaging: messagingReducer,
    employeeCalendar: EmployeeCalendarReducer,
    experiment: experimentReducer,
})
