import styled from '@emotion/styled'

export const ActionBarContainer = styled.section<{ isInModal?: boolean; noFilters?: boolean }>`
    position: relative;
    margin: 0 8px 12px 8px;
    box-shadow: ${({ isInModal }) => !isInModal && '0 1px 4px 0 #00000040'};
    border-radius: ${({ theme }) => theme.tokens.radiusSmall};
    background-color: ${({ theme }) => theme.componentExtensions.background.primary};
    padding: ${({ noFilters }) => {
        if (!noFilters) {
            return '20px 20px 33px'
        }

        return '20px'
    }};
`

export const TopRow = styled.div<{ noFilters?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
    margin-bottom: ${({ noFilters }) => !noFilters && '12px'};
`

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 250px;
    list-style: none;
    padding: 0;
    margin: 0;
`

export const ListItem = styled.li<{ divider?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    ${({ divider, theme }) =>
        divider &&
        `
        border-bottom: 1px solid ${theme.componentExtensions.border.secondary};
        padding-bottom: 8px;
        padding-top: 8px;
        margin-bottom: 8px;
    `};

    ${({ disabled, theme }) =>
        disabled &&
        `
        color: ${theme.componentExtensions.text.disabledDarker};
        pointer-events: none;
    `}

    .delete-settings-button {
        visibility: hidden;
    }

    :hover {
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};

        .delete-settings-button {
            visibility: visible;
        }
    }
`

export const SelectorContainer = styled.div`
    width: 240px;
`

export const FilterActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    height: 22px;
`

export const ValuePickersContainer = styled.div<{ filtersVisible?: boolean }>`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${({ filtersVisible }) => (filtersVisible ? '8px' : '0')};
    margin-bottom: ${({ filtersVisible }) => (filtersVisible ? '12px' : '0')};
`

export const PickerGroup = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 8px;
`

export const FiltersBadgeContainer = styled.div`
    height: 30px;
`
