import * as React from 'react'

import { Button } from '../Button'
import { MenuWithItems } from '../../Menu'

type TMenuWithItemsPropsLimited = Omit<React.ComponentProps<typeof MenuWithItems>, 'children'>

type TButtonProps = React.ComponentProps<typeof Button>

interface IButtonWithMenuProps
    extends TMenuWithItemsPropsLimited,
        Pick<TButtonProps, 'children' | 'disabled' | 'className' | 'variant' | 'large' | 'small'> {
    testId?: string
}

const anchorOrigin: TMenuWithItemsPropsLimited['anchorOrigin'] = {
    vertical: 'bottom',
    horizontal: 'left',
}

const ButtonWithMenu: React.FC<IButtonWithMenuProps> = ({
    children,
    disabled = false,
    className,
    variant,
    large,
    small,
    testId,
    ...MenuWithItemsProps
}) => {
    return (
        <MenuWithItems {...MenuWithItemsProps} anchorOrigin={anchorOrigin}>
            {(onMenuOpen) => (
                <Button
                    className={className}
                    disabled={disabled}
                    endIcon="keyboard_arrow_down"
                    onClick={onMenuOpen}
                    variant={variant}
                    testId={testId}
                    large={large}
                    small={small}
                >
                    {children}
                </Button>
            )}
        </MenuWithItems>
    )
}

export default ButtonWithMenu
