import { useAreFiltersAtDefaultState } from './hooks'
import { translate } from '../localization'
import { Button, PopoverWithComponent, TextInputV2 } from '../generic-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentPage } from '../navigation/State/NavigationSelectors'
import styled from '@emotion/styled'
import { KeyboardEvent, useState } from 'react'
import { SAVED_PICKERS_PREFIX } from '../value-picker/Utilities/ValuePickerValueUtilities'
import { getJSONItemFromLocalStorage, setJSONItemToLocalStorage } from '../generic-utilities'
import { displaySuccessToaster } from '../notifications'

type Props = {
    filterIds: string[]
}

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

const StyledButton = styled(Button)`
    margin-left: auto;
`

const SaveButton = styled(Button)`
    .MuiButton-label {
        font-size: 14px;
    }
`

const SaveFiltersButton = ({ filterIds }: Props): JSX.Element | null => {
    const dispatch = useDispatch()

    const currentPage = useSelector(selectCurrentPage)

    const [settingsName, setSettingsName] = useState<string | null>('')

    const { filtersAtDefaultState, filters } = useAreFiltersAtDefaultState(filterIds)

    if (filtersAtDefaultState) {
        return null
    }

    const handleSave = (handleClose: () => void) => {
        const filtersToSave = filters.filter(({ isDefault }) => !isDefault).map(({ id, value }) => ({ id, value }))

        const values = filtersToSave.reduce((obj, valuePicker) => ({ ...obj, [valuePicker.id]: valuePicker.value }), {})

        const settingsKey = `${SAVED_PICKERS_PREFIX}-${currentPage}`
        const currentSettings = getJSONItemFromLocalStorage<Record<string, Record<string, unknown>>>(settingsKey)

        setJSONItemToLocalStorage(`${SAVED_PICKERS_PREFIX}-${currentPage}`, {
            ...currentSettings,
            [settingsName ?? '']: values,
        })

        const message = translate('filtersSaved')
        dispatch(displaySuccessToaster(message))

        handleClose()
    }

    const handleKeyDown = (event: KeyboardEvent, handleClose: () => void) => {
        if (event.key === 'Enter' && settingsName?.length) {
            event.preventDefault()
            handleSave(handleClose)
        }
    }

    return (
        <PopoverWithComponent
            anchorOriginHorizontal={0}
            openPopoverRenderer={(onMenuOpen) => (
                <SaveButton startIcon="check" variant="text" onClick={onMenuOpen}>
                    {translate('tallenna-valinnat')}
                </SaveButton>
            )}
        >
            {(handleClose) => (
                <InputContainer>
                    <TextInputV2
                        value={settingsName}
                        label="Nimi"
                        onChange={setSettingsName}
                        onKeyDown={(event) => handleKeyDown(event, handleClose)}
                        autoFocus
                    />
                    <StyledButton disabled={!settingsName} onClick={() => handleSave(handleClose)}>
                        {translate('save-button')}
                    </StyledButton>
                </InputContainer>
            )}
        </PopoverWithComponent>
    )
}

export default SaveFiltersButton
