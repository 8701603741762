import { useCallback, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { RootState } from 'typesafe-actions'
import { IPageDateRangePickerOwnProps, selectValuePickerValue, setValuePickerValueAction } from '../../../value-picker'
import { DayRangePickerV2 } from '../../../generic-components'
import { formatForDataUsage, toDateObject } from '../../../dates'
import { translate } from '../../../localization'
import LegacyPageDateRangePicker from './LegacyPageDateRangePicker'
import ExperimentFlag from '../../../generic-utilities/FeatureFlag/ExperimentFlag'
import { EXPERIMENT_FLAGS } from '../../../generic-utilities/FeatureFlag/utils'

const PageDateRangePicker = ({ valuePickerId }: IPageDateRangePickerOwnProps): JSX.Element => {
    const dispatch = useDispatch()

    const value = useSelector((state: RootState) => selectValuePickerValue(state, valuePickerId)) as {
        start: moment.Moment
        end: moment.Moment
    }

    const [internalValue, setInternalValue] = useState<Record<string, moment.Moment | null>>({
        start: value.start,
        end: value.end,
    })

    const setValuePickerValue = useCallback(
        (newValue: any) => dispatch(setValuePickerValueAction(newValue, valuePickerId)),
        [dispatch, valuePickerId]
    )

    const formattedValue = {
        start: internalValue.start ? formatForDataUsage(internalValue.start) : null,
        end: internalValue.end ? formatForDataUsage(internalValue.end) : null,
    }

    const handleChange = (newValues: { start: string; end: string }) => {
        const newValueObjects = {
            start: newValues.start ? toDateObject(newValues.start) : null,
            end: newValues.end ? toDateObject(newValues.end) : null,
        }

        setInternalValue(newValueObjects)
        setValuePickerValue(newValueObjects)
    }

    return (
        <ExperimentFlag
            flag={EXPERIMENT_FLAGS.ACTION_BAR_RENEWAL}
            fallback={<LegacyPageDateRangePicker valuePickerId={valuePickerId} />}
        >
            <DayRangePickerV2
                valuePickerId={valuePickerId}
                startDay={formattedValue.start}
                endDay={formattedValue.end}
                startLabel={translate('starting')}
                endLabel={translate('ending')}
                onChange={handleChange}
                isEndDayErrored={false}
                isFieldErrored={false}
                isStartDayErrored={false}
                placeholder=""
            />
        </ExperimentFlag>
    )
}

export default PageDateRangePicker
