import * as React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import modernizr from 'modernizr'

import TValuePickerId from '../../../../value-picker/types/TValuePickerId'
import VuorotJaVapaatForms from './VuorotJaVapaatForms'
import { VUOROT_JA_VAPAAT_DATA_SOURCE_ID, VUOROT_JA_VAPAAT_LIST_ID } from '../Constants/VuorotJaVapaatConstants'
import { AccumulatedDataRowOld } from '../../../../accumulated-data'
import { ConfigurableListPageTemplate, selectListViewFilterIds, TListIconData } from '../../../../configurable-list'
import { PageContainer } from '../../../../styling'
import { PermissionableAction } from 'interfaces/IActionPermission'
import { Translation } from '../../../../localization'
import { closeAllModalsAction, openModalAction } from '../../../../modal'
import { EVENT_DELETION_CONFIRMATION_DIALOG_ID } from '../../../../event'
import HyvaksyntaStatusTabs from './HyvaksyntaStatusTabs'
import { FEATURE_FLAGS, useIsExperimentEnabled } from '../../../../generic-utilities/FeatureFlag/utils'

interface IDispatchProps {
    openModal: typeof openModalAction
    closeAllModals: typeof closeAllModalsAction
}

interface IOwnProps {
    isListVirtualized?: boolean
}

interface IStateProps {
    filterIds: TValuePickerId[]
}

interface IVuorotJaVapaatPageProps extends IStateProps, IDispatchProps, IOwnProps {}

export const VuorotJaVapaatPageUnconnected: React.FC<IVuorotJaVapaatPageProps> = ({
    closeAllModals,
    openModal,
    isListVirtualized = true,
}) => {
    const isExperimentEnabled = useIsExperimentEnabled(FEATURE_FLAGS.ACTION_BAR_RENEWAL)

    const [tapahtumaId, setTapahtumaId] = useState<string>('')

    const handleDeleteIconClick = (tapahtumaIdParam: string) => {
        setTapahtumaId(tapahtumaIdParam)

        openModal(EVENT_DELETION_CONFIRMATION_DIALOG_ID)
    }

    const handleDeletionModalClose = () => {
        setTapahtumaId('')
        closeAllModals()
    }

    const actions: TListIconData = {
        [PermissionableAction.DELETE]: {
            icon: 'delete',
            actionFunc: handleDeleteIconClick,
            tooltip: Translation.translateKey('remove-button'),
        },
    }

    // On any browser in which CSS style `position: sticky` works (basically all others except for IE11
    // and Edge until versions 15), the aggregated data is rendered on the bottom of the page. Later on
    // there will be a solution to make it work also on other browsers, and then this can be removed completely
    const accumulatedData = !modernizr.csspositionsticky && (
        <AccumulatedDataRowOld dataSourceId={VUOROT_JA_VAPAAT_DATA_SOURCE_ID} />
    )

    return (
        <>
            {!isExperimentEnabled && <HyvaksyntaStatusTabs listId={VUOROT_JA_VAPAAT_LIST_ID} />}
            <PageContainer>
                <ConfigurableListPageTemplate
                    accumulatedData={accumulatedData}
                    additionalActions={actions}
                    listId={VUOROT_JA_VAPAAT_LIST_ID}
                    listIsVirtualized={isListVirtualized}
                />
                <VuorotJaVapaatForms onDeletionModalClose={handleDeletionModalClose} tapahtumaId={tapahtumaId} />
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state: RootState): IStateProps => ({
    filterIds: selectListViewFilterIds(state, VUOROT_JA_VAPAAT_LIST_ID),
})

const mapDispatchToProps = {
    openModal: openModalAction,
    closeAllModals: closeAllModalsAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(VuorotJaVapaatPageUnconnected)
