import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

type Props = {
    count: number
    disabled?: boolean
    className?: string
    testID?: string
}

const Container = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) =>
        disabled
            ? theme.componentExtensions.buttons.bgButtonPrimaryDisabled
            : theme.componentExtensions.buttons.bgButtonPrimaryDefault};
    height: 18px;
    min-width: 18px;
    text-align: center;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.neutralsWhite100};
`

const SelectedCountLabel = styled(Typography)`
    font-size: 12px;
    padding: 0 4px;
    color: ${({ theme }) => theme.colors.neutralsWhite100};
`

const SelectionCountBadge = ({ count, disabled, className, testID }: Props): JSX.Element => {
    return (
        <Container disabled={disabled} className={className}>
            <SelectedCountLabel data-testid={testID}>{count}</SelectedCountLabel>
        </Container>
    )
}

export default SelectionCountBadge
