import styled from '@emotion/styled'
import { ListActionButtons, ListContainerGrid, selectSelectedItemIds } from '../configurable-list'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash-es'
import { RootState } from 'typesafe-actions'
import { isStatusTabsVisible } from './utils'

type Props = {
    listId: string
    accumulatedData?: ReactNode
    additionalButtons?: ReactNode
    onModalSubmitCallback?: () => void
}

const Container = styled.div<{ statusTabsVisible?: boolean }>`
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 56px;
    z-index: 3;
    background-color: #e8f6f8;
    box-shadow: 2px 2px 12px 5px #00000033;
    bottom: ${({ statusTabsVisible }) => (!statusTabsVisible ? '-40px' : '-10px')};
    border: 1px solid ${({ theme }) => theme.colors.primaryTeal30};
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
`

const ListActionsBar = ({
    listId,
    accumulatedData,
    additionalButtons,
    onModalSubmitCallback,
}: Props): JSX.Element | null => {
    const selectedItemIds = useSelector((state: RootState) => selectSelectedItemIds(state, listId))

    if (isEmpty(selectedItemIds)) {
        return null
    }

    const statusTabsVisible = isStatusTabsVisible(listId)

    return (
        <Container statusTabsVisible={statusTabsVisible}>
            <ListContainerGrid
                accumulatedData={accumulatedData}
                buttons={
                    <>
                        <ListActionButtons
                            listId={listId}
                            onModalSubmitCallback={onModalSubmitCallback}
                            includeStaticActions={false}
                            hideDisabledActions
                        />
                        {additionalButtons}
                    </>
                }
            />
        </Container>
    )
}

export default ListActionsBar
