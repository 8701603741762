import { Fragment, useCallback, useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import CreateIcon from '@material-ui/icons/Create'
import Divider from '@material-ui/core/Divider'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import IconMenu from '@material-ui/core/Menu'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LanguageIcon from '@material-ui/icons/Language'
import MenuItem from '@material-ui/core/MenuItem'
import styled from '@emotion/styled'
import Badge from '@material-ui/core/Badge'

import Styles from '../../../../constants/Styles'
import { translate } from '../../../../../localization'
import URL from '../../../../common/URL'
import { Icon, Tooltip } from '../../../../../generic-components'
import Asetukset from '../../../../constants/Asetukset'
import AsetuksetContext from '../../../../context/AsetuksetContext'
import LoginUserContext from '../../../../context/LoginUserContext'
import { logout as logoutQuery } from '../../../../../login'
import OhjeMenuItem from '../OhjeMenuItem'
import { openModalAction } from '../../../../../modal'
import { PasswordChangeForm } from '../../../../../legacy-newer'
import { getIsLegacyEnvironment } from '../../../../../legacy-environment-adapter'
import { ReleaseNotesDialogOpener } from '../../../../../release-notes'
import LanguageChangeForm from '../../../../../legacy-newer/Components/LanguageChangeForm/LanguageChangeForm'
// no cookie icons in @material-ui v4, so we'll use react-icons
import { MdOutlineCookie } from '@react-icons/all-files/md/MdOutlineCookie'
import { setCookiesAcceptedAction } from '../../../../action-creators'
import ExperimentToggle from '../../../../../generic-utilities/FeatureFlag/ExperimentToggle'
import { EXPERIMENT_FLAGS, FEATURE_FLAGS } from '../../../../../generic-utilities/FeatureFlag/utils'
import FeatureFlag from '../../../../../generic-utilities/FeatureFlag/FeatureFlag'

const TIETOSUOJASELOTE_URL_END_LEGACY = 'www/tietosuojaseloste.aspx'

const StyledBadge = styled(Badge)`
    width: 22px;
    margin-right: 18px;
`

const PlanierGuideOpener = ({ isAika }) => {
    const handleOpenGuide = () => {
        const url = isAika
            ? 'https://accountorhr.atlassian.net/wiki/spaces/MAT/overview'
            : 'https://accountorhr.atlassian.net/wiki/spaces/PLAN/overview'
        window.open(url)
    }

    const title = isAika ? 'Mepco Aika tietopankki' : 'Mepco Keikat tietopankki'

    return (
        <Tooltip title={title}>
            <StyledBadge color="secondary">
                <button onClick={handleOpenGuide} data-testid="STC_HEADER_HELP_BTN">
                    <Icon color={Styles.mainColor.white}>help</Icon>
                </button>
            </StyledBadge>
        </Tooltip>
    )
}

const StyledMenuItem = styled(MenuItem)`
    /* Override MUI media query style */
    &&& {
        min-height: 48px;
        justify-content: flex-start;
        padding: 6px 16px;
        font-family: Inter, arial;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3px;
    margin: 0 30px 0 auto;

    @media only screen and (max-width: 400px) {
        margin-right: 3px;
    }
`

const UserContainer = styled.button`
    color: ${Styles.mainColor.white};
    text-transform: capitalize;
    cursor: pointer;
    font-size: 12px;
    font-family: Inter, arial;
`

const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ExperimentToggleContainer = styled.div`
    margin-right: 20px;
`

const PersonIcon = ({ color }) => <Icon color={color}>person</Icon>

const UserBasicInfo = ({ user }) => {
    return <Fragment>{user.Id + ' ' + user.Sukunimi + ' ' + user.Etunimi}</Fragment>
}

const Instructions = ({ instructions, isOpen, ohjeinfoMenuRef, closeMenu }) => {
    const instructionComponents = instructions.map((ohjeInfo) => {
        const { OhjeId, Selite, Url } = ohjeInfo

        return <OhjeMenuItem key={OhjeId} link={Url} ohjeenTiedostonimi={Selite} ohjeId={OhjeId} />
    })

    return (
        <IconMenu
            anchorEl={ohjeinfoMenuRef.current}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            getContentAnchorEl={null}
            onClose={closeMenu}
            open={isOpen}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {instructionComponents}
        </IconMenu>
    )
}

export const MainMenu = ({ navItemsInDrawer }) => {
    const dispatch = useDispatch()

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [ohjeinfotOpen, setOhjeinfotOpen] = useState(false)
    const settings = useContext(AsetuksetContext)
    const user = useContext(LoginUserContext)
    const instructions = useSelector((state) => state.ohjeinfot)

    const mainElementRef = useRef()
    const ohjeinfoMenuRef = useRef()
    const isAika = settings.get('IsMepcoAikaEnabled')

    const toggleMenu = useCallback(() => {
        setMenuIsOpen((previousMenuIsOpen) => !previousMenuIsOpen)
    }, [])

    const closeMenu = useCallback(() => {
        setOhjeinfotOpen(false)
        setMenuIsOpen(false)
    }, [])

    const toggleOhjeinfot = useCallback(() => {
        setOhjeinfotOpen((previousOhjeinfotOpen) => !previousOhjeinfotOpen)
    }, [])

    const changePassword = useCallback(() => {
        dispatch(openModalAction('password-change.PasswordChangeForm'))

        closeMenu()
    }, [closeMenu, dispatch])

    const changeLanguage = useCallback(() => {
        dispatch(openModalAction('change-language-form'))
    }, [dispatch])

    const logout = useCallback(async () => {
        if (!getIsLegacyEnvironment()) {
            logoutQuery()

            const logoutUrl = settings.get('logoutUrl')
            if (logoutUrl) {
                URL.goto(logoutUrl)
                return
            }

            URL.goto(URL.oldClient(''))
        } else {
            URL.goto(URL.oldClient('logout.aspx'))
        }
    }, [settings])

    const openInformationConfidentualityDocument = () => {
        let url = ''

        if (getIsLegacyEnvironment()) {
            url = settings.get(Asetukset.Tietosuojaseloste_Linkki) || URL.oldClient(TIETOSUOJASELOTE_URL_END_LEGACY)
        } else {
            url = translate('tietosuojaseloste-url')
        }

        window.open(url)
    }

    const openCookieBanner = () => {
        localStorage.removeItem('cookieSettings')
        dispatch(setCookiesAcceptedAction(null))
        closeMenu()
    }

    return (
        <>
            <Container ref={mainElementRef}>
                <FeatureFlag flag={FEATURE_FLAGS.ACTION_BAR_RENEWAL} fallback={null}>
                    <ExperimentToggleContainer>
                        <ExperimentToggle flag={EXPERIMENT_FLAGS.ACTION_BAR_RENEWAL} />
                    </ExperimentToggleContainer>
                </FeatureFlag>

                {!getIsLegacyEnvironment() && <PlanierGuideOpener isAika={isAika} />}
                {!getIsLegacyEnvironment() && !isAika && <ReleaseNotesDialogOpener />}
                <UserContainer onClick={toggleMenu} data-testid="STC_HEADER_USER_MENU">
                    {getIsLegacyEnvironment() && navItemsInDrawer ? (
                        <PersonIcon color={Styles.mainColor.white} />
                    ) : (
                        <UserBasicInfo user={user} />
                    )}
                </UserContainer>
                <Icon color={Styles.mainColor.white} onClick={toggleMenu}>
                    arrow_drop_down
                </Icon>
                <IconMenu anchorEl={mainElementRef.current} onClose={closeMenu} open={menuIsOpen} variant="menu">
                    <MenuItemContainer>
                        <StyledMenuItem disabled>
                            <ListItemIcon>
                                <PersonIcon color={Styles.mainColor.black} />
                            </ListItemIcon>
                            <UserBasicInfo user={user} />
                        </StyledMenuItem>

                        <Divider />

                        <StyledMenuItem onClick={changePassword} autoFocus data-testid="STC_USER_MENU_CHANGE_PWRD">
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            {translate('password-change')}
                        </StyledMenuItem>
                        <StyledMenuItem onClick={logout} data-testid="STC_USER_MENU_LOGOUT">
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            {translate('sign-out-action')}
                        </StyledMenuItem>

                        {instructions.length > 0 && (
                            <StyledMenuItem onClick={toggleOhjeinfot} data-testid="STC_USER_MENU_INSTRUCTIONS">
                                <ListItemIcon>
                                    <ChevronLeft />
                                </ListItemIcon>
                                {translate('lataa-ohjeet')}
                                <Instructions
                                    instructions={instructions}
                                    isOpen={ohjeinfotOpen}
                                    ohjeinfoMenuRef={ohjeinfoMenuRef}
                                />
                            </StyledMenuItem>
                        )}

                        <div ref={ohjeinfoMenuRef} />

                        <StyledMenuItem
                            onClick={openInformationConfidentualityDocument}
                            data-testid="STC_USER_MENU_SECURITY_INFO"
                        >
                            <ListItemIcon>
                                <InfoOutline />
                            </ListItemIcon>
                            {translate('avaa-tietoturvaseloste')}
                        </StyledMenuItem>

                        <StyledMenuItem onClick={openCookieBanner} data-testid="STC_USER_MENU_ABOUT_COOKIES">
                            <ListItemIcon>
                                <MdOutlineCookie size={21} />
                            </ListItemIcon>
                            {translate('cookieBanner.manageCookies')}
                        </StyledMenuItem>

                        <StyledMenuItem onClick={changeLanguage} data-testid="STC_USER_MENU_CHANGE_LANG">
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            FI / EN
                        </StyledMenuItem>
                    </MenuItemContainer>
                </IconMenu>
            </Container>

            <PasswordChangeForm />
            <LanguageChangeForm />
        </>
    )
}

export default MainMenu
