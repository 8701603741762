import { PropsWithChildren } from 'react'
import { isFeatureEnabled } from './utils'

type Props = {
    flag: string
    fallback: JSX.Element | null
}

const FeatureFlag = ({ flag, fallback = null, children }: PropsWithChildren<Props>): JSX.Element | null => {
    return isFeatureEnabled(flag) ? <>{children}</> : fallback
}

export default FeatureFlag
