export default {
    timeSpan: {
        fi: 'Aikajakso',
        sv: '',
        en: 'Time span',
        ru: '',
        sk: '',
        pl: '',
    },
    starting: {
        fi: 'Alkaen',
        sv: '',
        en: 'Starting',
        ru: '',
        sk: '',
        pl: '',
    },
    ending: {
        fi: 'Päättyen',
        sv: '',
        en: 'Ending',
        ru: '',
        sk: '',
        pl: '',
    },
    filtersSaved: {
        fi: 'Suodattimet tallennettu',
        sv: '',
        en: 'Filters saved',
        ru: '',
        sk: '',
        pl: '',
    },
    savedFilters: {
        fi: 'Tallennetut valinnat',
        sv: '',
        en: 'Saved selections',
        ru: '',
        sk: '',
        pl: '',
    },
    noSavedFilters: {
        fi: 'Ei tallennettuja valintoja',
        sv: '',
        en: 'No saved selections',
        ru: '',
        sk: '',
        pl: '',
    },
    'tallenna-valinnat': {
        fi: 'Tallenna valinnat',
        sv: '',
        en: 'Save selections',
        ru: '',
        sk: '',
        pl: '',
    },
    allRosters: {
        fi: 'Kaikki työvuorolistat',
        sv: '',
        en: 'All rosters',
        ru: '',
        sk: '',
        pl: '',
    },
    createNewRoster: {
        fi: 'Luo uusi työvuorolista',
        sv: '',
        en: 'Create new roster',
        ru: '',
        sk: '',
        pl: '',
    },
    createNewRosterFromView: {
        fi: 'Luo työvuorolista valitusta näkymästä',
        sv: '',
        en: 'Create roster selected view',
        ru: '',
        sk: '',
        pl: '',
    },
    resources: {
        event: {
            fi: 'tapahtuma',
            sv: '',
            en: 'event',
            ru: '',
            sk: '',
            pl: '',
        },
        employee: {
            fi: 'työntekijä',
            sv: '',
            en: 'employee',
            ru: '',
            sk: '',
            pl: '',
        },
        transaction: {
            fi: 'toteumarivi',
            sv: '',
            en: 'transaction',
            ru: '',
            sk: '',
            pl: '',
        },
    },
}
