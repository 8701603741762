import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { selectIsExperimentEnabled } from '../../redux/experiment/experimentSelectors'

type Props = {
    flag: string
    fallback: JSX.Element | null
}

const ExperimentFlag = ({ flag, fallback = null, children }: PropsWithChildren<Props>): JSX.Element | null => {
    const isEnabled = useSelector((state: RootState) => selectIsExperimentEnabled(state, flag))

    return isEnabled ? <>{children}</> : fallback
}

export default ExperimentFlag
