import { DayRangePickerV2, PopoverWithComponent, TranslatedTypography } from '../../generic-components'
import { useQuery } from '../../generic-utilities'
import styled from '@emotion/styled'

import moment, { Moment } from 'moment'
import { formatForDataUsage, formatForDisplay, toDateObject } from '../../dates'
import { IValuePickerCommonOwnProps } from '../../value-picker'
import Dropdown from '../../generic-components/Inputs/Dropdown/Dropdown'
import { ERequestMethod } from '../../rest-api'
import { useEffect, useState } from 'react'
import { removeDuplicateObjectsInArrayBasedOnKey } from '../../generic-utilities/ArrayUtils'
import { getWeeksCountBetweenDates } from '../../dates/Utilities/DateUtilities'
import { translate } from '../../localization'
import { isNil } from 'lodash-es'
import { SelectorComponent } from '../../generic-components/Inputs/Dropdown/Selector'
import Label from '../../generic-components/Inputs/Dropdown/Label'
import Chip from '../../generic-components/Chip'
import { FEATURE_FLAGS, useIsExperimentEnabled } from '../../generic-utilities/FeatureFlag/utils'
import { SelectorContainer } from '../../action-bar/layoutComponents'
import ExperimentFlag from '../../generic-utilities/FeatureFlag/ExperimentFlag'

const StyledPopoverWithComponent = styled(PopoverWithComponent)``

const TitleContainer = styled.div`
    padding-bottom: 20px;
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const getDisplayValue = (calendarDates: { startDate: Moment; endDate: Moment }) => {
    const { startDate, endDate } = calendarDates
    return `${formatForDisplay(startDate)} – ${formatForDisplay(endDate)}`
}

const getDisplayValueWithWeekCount = (calendarDates: { startDate: Moment; endDate: Moment }) => {
    const { startDate, endDate } = calendarDates
    const weeksCount = getWeeksCountBetweenDates(startDate, endDate, 0)
    return `${formatForDisplay(startDate)}–${formatForDisplay(endDate)} - ${weeksCount} ${translate('viikkoa')}`
}

export interface IDateRangePickerProps extends IValuePickerCommonOwnProps<{ startDate: Moment; endDate: Moment }> {
    renderAsChip?: boolean
    label?: string
}

interface IPeriods {
    Id: number
    StartDate: string
    EndDate: string
}

const DateRangeV2Picker = ({
    onChange,
    value,
    valuePickerId,
    renderAsChip,
    label = 'timeSpan',
}: IDateRangePickerProps): JSX.Element => {
    const [internalValue, setInternalValue] = useState({ startDate: moment(), endDate: moment().add(1, 'year') })

    const [internalValueDidChange, setInternalValueDidChange] = useState(false)

    const isExperimentEnabled = useIsExperimentEnabled(FEATURE_FLAGS.ACTION_BAR_RENEWAL)

    const formattedValue = {
        start: formatForDataUsage(internalValue.startDate),
        end: formatForDataUsage(internalValue.endDate),
    }

    const dateString = getDisplayValue(internalValue)

    useEffect(() => {
        if (
            !isNil(value) &&
            !internalValueDidChange &&
            !moment(value.startDate).isSame(internalValue.startDate, 'day') &&
            !moment(value.endDate).isSame(internalValue.endDate, 'day')
        ) {
            setInternalValue(value)
        }

        if (isNil(value)) {
            onChange(internalValue)
        }
    }, [internalValue, internalValueDidChange, onChange, value])

    const _onChange = (newValues: { start: string; end: string }) => {
        const newValueObjects = { startDate: toDateObject(newValues.start), endDate: toDateObject(newValues.end) }

        setInternalValue(newValueObjects)

        setInternalValueDidChange(true)
    }

    const onDialogClose = () => internalValueDidChange && onChange(internalValue)

    const { data, isLoading } = useQuery<{ ListData: IPeriods[] }>('Period/Search', ERequestMethod.POST, {
        Filters: {
            PeriodTypes: [1],
            OverlapsDateRange: { Alku: moment().subtract(3, 'months'), Loppu: moment().add(6, 'months') },
        },
    })

    const options = removeDuplicateObjectsInArrayBasedOnKey(
        data?.ListData.map((period) => {
            return {
                Id: period.Id,
                startDate: period.StartDate,
                Name: getDisplayValueWithWeekCount({
                    startDate: moment(period.StartDate),
                    endDate: moment(period.EndDate),
                }),
                endDate: period.EndDate,
            }
        }) ?? [],
        'Name'
    )

    const handleOnPeriodChange = (selectedRowId: number[]) => {
        const index = options.findIndex((option) => option.Id === selectedRowId[0])
        _onChange({ start: options[index].startDate, end: options[index].endDate })
    }

    return (
        <Label text={isExperimentEnabled && !renderAsChip && label ? translate(label) : ''}>
            <StyledPopoverWithComponent
                anchorOriginHorizontal="left"
                onClose={onDialogClose}
                openPopoverRenderer={(onMenuOpen) => (
                    <ExperimentFlag
                        flag={FEATURE_FLAGS.ACTION_BAR_RENEWAL}
                        fallback={
                            <Chip disabled={false} isSelectionIconRendered label={dateString} onClick={onMenuOpen} />
                        }
                    >
                        {!renderAsChip ? (
                            <SelectorContainer>
                                <SelectorComponent
                                    options={[{ Id: 0, Name: dateString }]}
                                    value={[0]}
                                    onClick={onMenuOpen}
                                    fullWidth
                                />
                            </SelectorContainer>
                        ) : (
                            <Chip disabled={false} isSelectionIconRendered label={dateString} onClick={onMenuOpen} />
                        )}
                    </ExperimentFlag>
                )}
                closeWithEnter
            >
                <TitleContainer>
                    <TranslatedTypography variant="subtitle1">
                        workforce-scheduling.ActionBar.DatePicker.Title
                    </TranslatedTypography>
                </TitleContainer>
                <InputContainer>
                    <DayRangePickerV2
                        endDay={formattedValue.end}
                        isEndDayErrored={false}
                        isFieldErrored={false}
                        isStartDayErrored={false}
                        onChange={_onChange}
                        startDay={formattedValue.start}
                        valuePickerId={valuePickerId}
                    />
                    <Dropdown
                        label={translate('Jaksot')}
                        isMultiselect={false}
                        value={[0]}
                        options={options}
                        placeholder={translate('selectPeriod')}
                        onChange={handleOnPeriodChange}
                        disabled={isLoading}
                        showClearSelection={false}
                        fullWidth
                    />
                </InputContainer>
            </StyledPopoverWithComponent>
        </Label>
    )
}

export default DateRangeV2Picker
